<template>
  <div class="d-flex align-center black--text" @click.stop>
    <span>권한:</span>
    <CrSelect
      class="ml-2"
      :items="items"
      :value.sync="_authority"
      :nudgeLeft="10"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .cr-flow-select.v-input.v-select {
    max-width: 120px;
    .v-input__control {
      width: auto;
      flex-grow: unset;

      .v-input__slot {
        padding: 0px;
        min-height: 24px;

        fieldset {
          border-color: transparent;
        }
        .v-select__slot {
          width: auto;
          .v-select__selections {
            padding: 0px;
            .v-select__selection {
              margin: 0px;
              max-width: 100%;
              color: var(--v-primary-base);
            }
            input {
              display: none;
            }
          }
          .v-input__append-inner {
            margin: 0px;
            padding: 0px;
            .v-input__icon.v-input__icon--append {
              width: 20px;
              min-width: 20px;
              button {
                color: var(--v-primary-base);
                font-size: 20px;
              }
            }
          }
        }
      }

      &:hover {
        .v-input__slot {
          .v-select__slot {
            &::before {
              position: absolute;
              top: -1px;
              bottom: 1px;
              left: -6px;
              right: 0px;
              content: "";
              background: rgba(0, 0, 0, 0.03);
              border: thin solid var(--v-primary-base);
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import CrSelect from "@/flow/views/components/commons/select";

export default {
  components: { CrSelect },
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    items() {
      return [
        { text: "게스트", value: "READ" },
        { text: "편집자", value: "EDIT" },
        { text: "운영자", value: "ADMIN" }
        // { text: "마스터", value: "MASTER" }
      ];
    },
    _authority: {
      get() {
        return this.member.authority;
      },
      set(authority) {
        const { userId: memberId } = this.member;
        this.updateMemberAuthority({ memberId, authority });
      }
    }
  },
  methods: {
    ...mapActions("flowProjects", ["updateMemberAuthority"])
  }
};
</script>
